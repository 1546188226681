<template>
	<div class="app-main__inner">
		<div class="container-fluid mb-3">
			<div class="col-md-12 -mt-2 border rounded bg-white p-3" v-loading='loadingFirm'>
				<div class="row space-y-1">
					<div class="col-md-6">
						<div class="form-group">
							<label for="inputCompanyName"
								   class="text-xs mb-1 col-form-label font-bold  whitespace-nowrap">{{
									$t('firstName')
								}}</label>
							<div>
								<input type="text" v-model="firm.name"
									   class="h-c33 form-control font-light text-xs input-shadow-box">
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label for="city" class="text-xs mb-1 col-form-label font-bold  whitespace-nowrap">{{
									$t('city')
								}}</label>
							<div>
								<input type="text" v-model="firm.city"
									   class="h-c33 form-control font-light text-xs input-shadow-box">
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label for="inputCompanyNumber"
								   class="text-xs mb-1 col-form-label font-bold  whitespace-nowrap">{{
									$t('companyNumber')
								}}</label>
							<div>
								<input type="text" v-model="firm.company_id"
									   class="h-c33 form-control font-light text-xs input-shadow-box">
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label for="city" class="text-xs mb-1 col-form-label font-bold  whitespace-nowrap">{{
									$t('phoneNumber')
								}}</label>
							<div>
								<input type="text" v-model="firm.phone"
									   class="h-c33 form-control font-light text-xs input-shadow-box">
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label for="inputCompanyAddress"
								   class="text-xs mb-1 col-form-label font-bold  whitespace-nowrap">{{
									$t('billingAddress')
								}}</label>
							<div>
								<input type="text" name="inputCompanyAddress" v-model="firm.billing_address"
									   class="h-c33 form-control font-light text-xs input-shadow-box">
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label for="email" class="text-xs mb-1 col-form-label font-bold  whitespace-nowrap">{{
									$t('email')
								}}</label>
							<div>
								<input type="email" v-model="firm.email"
									   class="h-c33 form-control font-light text-xs input-shadow-box">
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label for="inputCompanyZipcode"
								   class="text-xs mb-1 col-form-label font-bold  whitespace-nowrap">{{
									$t('zipCode')
								}}</label>
							<div>
								<input type="text" v-model="firm.zip_code"
									   class="h-c33 form-control font-light text-xs input-shadow-box">
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label for="invoiceEmail" class="text-xs mb-1 col-form-label font-bold  whitespace-nowrap">{{
									$t('invoiceEmail')
								}}</label>
							<div>
								<input type="email" v-model="firm.invoice_email"
									   class="h-c33 form-control font-light text-xs input-shadow-box">
							</div>
						</div>
					</div>
					<div class="col-md-6" v-if="firm.affiliate_type === 1">
						<div class="form-group">
							<label for="bankgiro_number"
								   class="text-xs mb-1 col-form-label font-bold  whitespace-nowrap">{{
									$t('bankGiroNumber')
								}}</label>
							<div>
								<input type="text" v-model="firm.bankgiro_number"
									   class="h-c33 form-control font-light text-xs input-shadow-box">
							</div>
						</div>
					</div>
					<div class="col-md-6" v-if="firm.affiliate_type === 1">
						<div class="form-group">
							<label for="bank" class="text-xs mb-1 col-form-label font-bold  whitespace-nowrap">{{
									$t('bank')
								}}</label>
							<div>
								<input type="text" v-model="firm.bank"
									   class="h-c33 form-control font-light text-xs input-shadow-box">
							</div>
						</div>
					</div>
					<div class="text-center mt-3 col-md-12">
                        <span>
                            <button @click="updateUserCompany" :disabled="updating"
									class="btn btn-theme-custom btn-primary btn-wide cursor-not-allowed">
                                <span v-if="updating" class="flex align-items-center space-x-3">
                                    <svg class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
										 fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
											stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor"
										  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    {{ $t('updating') }}
                                </span>
                                <span v-else>{{ $t('update_information') }}</span>
                            </button>
                        </span>
					</div>
				</div>
			</div>
			<div class="flex justify-end mt-3 mb-3">
				<h4 class="text-xs font-bold text-gray-700 mr-1">Partner ID: </h4>
				<span class="font-bold text-xs house-theme-sm-head whitespace-nowrap">{{
						GET_USER.accounting_firm_id
					}}</span>
			</div>

			<div class="mb-10">
				<div class="row mb-2 mt-3 mb-3">
					<div class="col-md-12 flex justify-between">
						<div>
							<h2 class="font-semibold text-base text-gray-900 mb-2" v-if="section === 1">
								{{ $t('accountant') }}</h2>
							<h2 class="font-semibold text-base text-gray-900 mb-2" v-else>{{
									$t('connected_companies')
								}}</h2>
						</div>
						<div class="flex align-items-center justify-end space-x-6">
							<span data-cy='assignAccountant'
								  class="font-bold  p-2 button-margin rounded border theme-blue text-xs whitespace-nowrap cursor-pointer font-light"
								  @click="assignAccountant">{{ $t('assign_companies') }}</span>
							<span data-cy='connectedCom' :class="{'button-color-active': section === 2}"
								  class="font-bold  p-2 button-margin rounded h-8 border theme-blue text-xs whitespace-nowrap cursor-pointer font-light"
								  @click="connectedCompany">{{ $t('connected') }}</span>
							<span data-cy='allAccountant' :class="{'button-color-active': section === 1}"
								  class="font-bold button-margin p-2 h-8 rounded border theme-blue text-xs whitespace-nowrap cursor-pointer font-light"
								  @click="section = 1">{{ $t('accountant') }}</span>
							<div class="form-grou" v-if="section === 1">
								<div class="input-icons">
									<i class="iconly-brokenSearch"></i>
									<input type="text" @input="searchInputTrigger" v-model="vSearchAccount"
										   class="text-sm h-8 form-control custom-search"
										   v-bind:placeholder="$t('search')">
								</div>
							</div>
							<div class="form-grou" v-if="section === 2">
								<div class="input-icons">
									<i class="iconly-brokenSearch"></i>
									<input type="text" @input="searchCompanyInputTrigger" v-model="vSearchCompany"
										   class="text-sm h-8 form-control custom-search"
										   v-bind:placeholder="$t('search')">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="section === 1">
					<table style="width: 100%;" id="exampledestroy"
						   class="table table-hover table-striped table-bordered"
					>
						<thead>
						<tr>
							<th class="text-xs font-bold"><span class="iconly-boldProfile">Serial</span></th>
							<th class="text-xs text-gray-800 font-bold">
								<div class="d-flex justify-content-between">{{ $t('name') }}<span><i
									@click="ascendingOrderName" class="lnr-arrow-up cursor-pointer"></i><i
									@click="descendingOrderName" class="lnr-arrow-down cursor-pointer"></i></span></div>
							</th>
							<th class="text-xs text-gray-800 font-bold">{{ $t('role') }}</th>
							<th class="text-xs text-gray-800 font-bold">{{ $t('email') }}</th>
							<th class="text-xs text-gray-800 font-bold">
								<div class="d-flex justify-content-between">{{ $t('dateAdded') }}<span><i
									@click="sortDateAscending" class="lnr-arrow-up cursor-pointer"></i><i
									@click="sortDateDescending" class="lnr-arrow-down cursor-pointer"></i></span></div>
							</th>
							<th class="text-xs text-gray-800 font-bold">{{ $t('management') }}</th>
						</tr>
						</thead>
						<tbody v-loading='vLoadingFirm'>
						<tr v-for="(manager, i) in GET_FIRM_ACCOUNTANT.results" :key="i">
							<td class="house-theme-sm-head">{{ i + 1 }}</td>
							<td class="text-xs font-light">
								{{ manager.first_name + ' ' + manager.last_name | capitalizeEachString }}
							</td>
							<td class="text-xs font-light">Accountant</td>
							<td class="text-xs font-light">{{ manager.email }}</td>
							<td class="text-xs font-light">{{ manager.date_joined.split('T')[0] }}</td>
							<td class="flex space-x-2">
							<span @click="specifyCompany(manager)"
								  class="text-base cursor-pointer iconly-brokenAdd-User icon-color"></span>
								<span @click="getFirmUserID(manager.id,GET_USER.accounting_firm_id)"
									  class="text-base cursor-pointer iconly-brokenEdit icon-color"></span>
								<span @click="removeAccount(GET_USER.accounting_firm_id,manager.id)"
									  class="text-base cursor-pointer iconly-brokenDelete icon-color"></span>
							</td>
						</tr>
						<!-- <tr v-if="allAccountants.length < 1" class="odd text-center"><td colspan="5" class="dataTables_empty text-xs" valign="top">{{ $t('noDataTable') }}</td></tr> -->
						</tbody>
					</table>
					<div class="float-right mb-5"
						 v-if="vLoadingFirm && GET_FIRM_ACCOUNTANT.total_page && section === 1">
						<paginate
							:page-count="GET_FIRM_ACCOUNTANT.total_page"
							:click-handler="changePageNumber"
							:prev-text="$t('prev')"
							:next-text="$t('next')"
							:container-class="'pagination'">
						</paginate>
					</div>
				</div>
				<div v-if="section === 2">
					<table style="width: 100%;" id="exampledestroy"
						   class="table table-hover table-striped table-bordered"
					>
						<thead>
						<tr>
							<th class="text-xs">{{ $t('companyName') }}</th>
							<th class="text-xs">{{ $t('companyNumber') }}</th>
							<!--							<th class="text-xs">{{ $t('accountant') }}</th>-->
							<th class="text-xs">Actions</th>
						</tr>
						</thead>
						<tbody v-loading='vLoadingCompany'>
						<tr v-for="(company, i) in GET_USERS_COMPANY.results" :key="i">
							<td class="text-xs font-light">
								<el-tooltip :content="$t('enterCompanyInfo')" placement="top">
									<span class="cursor-pointer" @click="getCompanyUserID(company.id)">{{
											company.company.name | capitalizeNames
										}}</span>
								</el-tooltip>
							</td>
							<td class="text-xs font-light">
								{{ company.company.company_id }}
							</td>
							<!--							<td class="text-xs font-light">-->

							<!--							</td>-->
							<td class="flex space-x-2">
								<span @click="getCompanyUserID(company.id)"
									  class="text-base cursor-pointer iconly-brokenEdit icon-color"></span>
							</td>
						</tr>
						<!-- <tr v-if="allAccountants.length < 1" class="odd text-center"><td colspan="5" class="dataTables_empty text-xs" valign="top">{{ $t('noDataTable') }}</td></tr> -->
						</tbody>
					</table>
					<div class="float-right mb-5" v-if="GET_USERS_COMPANY.results">
						<paginate
							:page-count="GET_USERS_COMPANY.total_page"
							:click-handler="changeCompanyPageNumber"
							prev-text="<"
							next-text=">"
							:container-class="'pagination'">
						</paginate>
					</div>
				</div>

			</div>
		</div>


		<el-dialog
			:visible.sync="paymentCardModal"
			width="40%"
			:handleClose="handleClose"
			center>
			<div class="container">
				<div class="row">
					<div id="card-element" class="col-md-12">

					</div>
				</div>
			</div>
		</el-dialog>

		<Notification/>
	</div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import Paginate from 'vuejs-paginate'
import bus from '../../bus'
import debounce from 'lodash/debounce'
import {Tooltip, Dialog} from 'element-ui'
import {getFirm} from "../../services/storage-window";

Vue.use(Tooltip)
Vue.use(Dialog)

export default {
	name: 'billing-dashboard',
	components: {
		Notification: () => import('./../../customComponent/NotificationAlert'),
		paginate: Paginate,
		// Dialog,
		// Tooltip
	},
	data() {
		return {
			section: 1,
			PaymentType: null,
			partnerID: '',
			firm: {
				name: '',
				firm_number: '',
				zip_code: '',
				city: '',
				phone: '',
				billing_address: '',
				email: '',
				invoice_email: '',
				affiliate_type: '',
				bank: '',
				bankgiro_number: '',
			},
			allCompanies: [],
			allAccountants: [],
			copyAllAccountant: [],
			history: [],
			updating: false,
			loadingFirm: false,
			paymentCardModal: false,
			vLoadingFirm: true,
			vLoadingCompany: true,
			vSearchCompany: '',
			vSearchAccount: '',

		}
	},
	computed: {
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			USER_REFRESH: 'auth/USER_REFRESH',
			GET_USER: 'customer/GET_USER',
			GET_USERS_COMPANY: 'master_accountant/GET_USERS_COMPANY',
			GET_USER_COMPANY_ID: 'customer/GET_USER_COMPANY_ID',
			GET_COMPANY_BILLING: 'customer/GET_COMPANY_BILLING',
			GET_PAYMENT_TYPE: 'customer/GET_PAYMENT_TYPE',
			GET_FIRM: 'house/GET_FIRM',
			GET_FIRM_ACCOUNTANT: 'master_accountant/GET_FIRM_ACCOUNTANTS',
			GET_ALL_COMPANY: 'house/GET_ALL_COMPANY',
		})
	},
	created() {
		this.getAuthUser()
	},
	mounted() {
		// const { partner } = this.$route.params
		// if (partner) {
		//     this.partnerID = partner
		//     this.getFirmInformation(partner)
		//     this.getFirmAccountants(partner)
		//     // this.getConnectCompany(partner)
		//     // bus.$emit('connected-com', partner)
		// }
		// bus.$on('assign', _ => {
		//     this.getFirmAccountants(partner)
		// })


	},
	methods: {
		changeCompanyPageNumber(num) {
			this.vLoadingCompany = true
			this.vLoadingCompany = true
			let partner = this.getPartnerId(this.GET_USER.accounting_firm_id)
			this.$store.dispatch('master_accountant/getConnectCompany', {URL: this.$services.endpoints.MASTER_ACCOUNTANT_COMPANIES + `${partner}/?page=${num}`}).then(_ => {
				this.vLoadingCompany = false
			})
			// this.$store.dispatch('house/getAllCompanies', payload)
			// 	.then(_ => {
			// 		this.vLoadingCompany = false
			// 	}).catch(err => {
			// 	this.vLoadingCompany = false
			// 	if (err.response.status === 400) {
			// 		if (err.response.data.code === 401) {
			// 			this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
			// 				.then(_ => {
			// 					this.getAllCompanies()
			// 				})
			// 				.catch(_ => {
			// 					this.$store.commit('auth/CLEAR_AUTH_USER', null)
			// 					window.Bus.$emit('sign-out')
			// 				})
			// 		}
			// 	}
			// })
		},
		getCompanyUserID(userID) {
			this.$store.commit('customer/SET_USER_COMPANY_ID', userID)
			setTimeout(() => {
				window.open('/automatisera', '_blank')
			}, 1000)
		},
		searchInputTrigger: debounce(function (event) {
			this.vLoadingFirm = true
			let partner = this.getPartnerId(this.GET_USER.accounting_firm_id)
			if (this.vSearchAccount) {
				this.$store.dispatch('master_accountant/getFirmAccountants', {URL: this.$services.endpoints.MASTER_ACCOUNTANT_FIRM_ACCOUNTANT + `${partner}/?search=${this.vSearchAccount}`})
					.then(_ => {
						this.vLoadingFirm = false
					}).catch(err => {
					this.vLoadingFirm = false
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
									this.getFirmAccountants(this.GET_USER.accounting_firm_id)
								})
								.catch(_ => {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out-accountant')
								})
						}
					}
				})
			} else {
				this.getFirmAccountants(this.GET_USER.accounting_firm_id)
			}

		}, 500),
		searchCompanyInputTrigger: debounce(function (event) {
			if (this.vSearchCompany) {
				this.vLoadingCompany = true
				let partner = this.getPartnerId(this.GET_USER.accounting_firm_id)
				this.$store.dispatch('master_accountant/getConnectCompany', {URL: this.$services.endpoints.MASTER_ACCOUNTANT_COMPANIES + `${partner}/?search=${this.vSearchCompany}`}).then(_ => {
					this.vLoadingCompany = false
				})
			} else {
				this.getConnectCompanies(this.GET_USER.accounting_firm_id)
			}
		}, 500),
		filterAccountants(event) {
			this.$store.commit('house/FILTER_ACCOUNTANTS', event.target.value)
		},
		handleClose() {
			this.paymentCardModal = false
		},
		getAuthUser() {
			const URL = this.$services.endpoints.GET_USER
			this.$store.dispatch('customer/getAuthUser', {URL})
				.then(res => {
					if (res.data.success) {
						//console.log(this.GET_USER)
						if ([3, 2].includes(this.GET_USER.dashboard_type) === false) {
							this.$router.replace({name: 'accountantdash'})
						} else {
							this.getFirmInformation(this.GET_USER.accounting_firm_id)
							this.getFirmAccountants(this.GET_USER.accounting_firm_id)
							this.getConnectCompanies(this.GET_USER.accounting_firm_id)
						}
					}
				}).catch(err => {
				// console.log(err)
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getAuthUser()
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out-accountant')
							})
					}
				}
			})
		},
		getPartnerId(partnerId) {
			let partner = ''
			if (partnerId) {
				partner = partnerId
			} else if (getFirm()) {
				partner = JSON.parse(getFirm()).partner
			} else if (this.firm.accounting_partner_id) {
				partner = this.firm.accounting_partner_id
			}
			return partner
		},
		getConnectCompanies(partnerId) {
			let partner = this.getPartnerId(partnerId)

			this.$store.dispatch('master_accountant/getConnectCompany', {URL: this.$services.endpoints.MASTER_ACCOUNTANT_COMPANIES + `${partner}`}).then(_ => {
				this.vLoadingCompany = false
			})
		},
		getFirmInformation(partnerID) {
			this.loadingFirm = true
			// let partner = ''
			// if (partnerID) {
			// 	partner = partnerID
			// } else if (getFirm()) {
			// 	let partner = JSON.parse(getFirm()).partner
			// }
			let partner = this.getPartnerId(partnerID)
			this.$store.dispatch('house/getFirmInformation', {URL: this.$services.endpoints.IN_HOUSE_UPDATE_FIRM + `${partner}/`})
				.then(res => {
					this.loadingFirm = false
					if (res.data.success) {
						this.firm = res.data.data

					}
				}).catch(err => {
				this.loadingFirm = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getFirmInformation(partnerID)
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out-accountant')
							})
					}
				}
			})
		},
		getFirmAccountants(partnerID) {
			this.vLoadingFirm = true
			let partner = this.getPartnerId(partnerID)
			this.$store.dispatch('master_accountant/getFirmAccountants', {URL: this.$services.endpoints.MASTER_ACCOUNTANT_FIRM_ACCOUNTANT + `${partner}/`})
				.then(_ => {
					this.vLoadingFirm = false
				}).catch(err => {
				this.vLoadingFirm = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getFirmAccountants(partnerID)
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out-accountant')
							})
					}
				}
			})
		},
		changePageNumber(num) {
			let partner = this.getPartnerId(this.GET_USER.accounting_firm_id)
			this.$store.dispatch('house/getFirmAccountants', {URL: this.$services.endpoints.MASTER_ACCOUNTANT_FIRM_ACCOUNTANT + `${partner}/?page=${num}`})
		},
		connectedCompany() {
			this.section = 2
			bus.$emit('connected-com', this.firm.accounting_partner_id)
			// this.$store.commit('OPEN_MODAL_UTIL')
			// this.$store.commit('utility/OPEN_INVITE_MODAL')
		},
		specifyCompany(chosenAccountant) {
			let partner = this.getPartnerId(this.GET_USER.accounting_firm_id)
			const d = {
				chosenAccountant,
				partner_id: partner
			}
			window.Bus.$emit('showUserSpecificFirm', d)
		},
		assignAccountant() {
			let partner = this.getPartnerId(this.GET_USER.accounting_firm_id)
			window.Bus.$emit('assignAccountant', {partner_id: partner})
		},
		getFirmUserID(accountant_id, firm) {
			if (accountant_id && firm) {
				this.$store.commit('customer/SET_USER_FIRM_ID', {accountant_id, partner: firm})
				setTimeout(() => {
					window.open('/accountant', '_blank')
				}, 1000)
			}
		},
		ascendingOrderName() {
			// this.$store.commit('house/ASCENDING_ORDER_NAME')
		},
		descendingOrderName() {
			// this.$store.commit('house/DESCENDING_ORDER_NAME')
		},
		sortDateAscending() {
			// this.$store.commit('house/SORT_DATE_ASC')
		},
		sortDateDescending() {
			// this.$store.commit('house/SORT_DATE_DESC')
		},
		updateUserCompany() {
			if (this.firm.name === '' || this.firm.company_id === null) {
				this.$services.helpers.notification(this.$t('requireCompanyNumber'), 'error', this)
				return false
			}
			if (this.firm.billing_address === '') {
				this.$services.helpers.notification(this.$t('requireCompanyAddress'), 'error', this)
				return false
			}

			if (!this.$services.helpers.validateEmailAddress(this.firm.email)) {
				this.$services.helpers.notification(this.$t('invalidEmail'), 'error', this)
			}

			if (this.validateCompanyNumber(this.firm.company_id)) {
				this.$services.helpers.notification(this.$t('invalidCompany'), 'error', this)
				return false
			}
			if (this.firm.phone === '') {
				this.$services.helpers.notification(this.$t('requirePhonenumber'), 'error', this)
				return false
			}
			if (this.firm.zip_code === '') {
				this.$services.helpers.notification(this.$t('requireZipCode'), 'error', this)
				return false
			}
			if (this.firm.city === '') {
				this.$services.helpers.notification(this.$t('requireCity'), 'error', this)
				return false
			}
			this.updating = true
			let payload = {}
			Object.keys(this.firm).map(item => {
				if (this.firm[item]) {
					payload[item] = this.firm[item]
				}
			})
			let partner = this.getPartnerId(this.GET_USER.accounting_firm_id)

			this.$store.dispatch('house/updateFirmInfo', {
				data: payload,
				URL: this.$services.endpoints.IN_HOUSE_UPDATE_FIRM + `${partner}/`
			})
				.then(res => {
					this.updating = false
					if (res.data.success) {
						this.$services.helpers.notification(this.$t('successCompanyUpdated'), 'success', this)
					}
				}).catch(err => {
				this.updating = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.updateUserCompany()
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out-accountant')
							})
					}
					if (err.response.data.code === 500) {
						this.$services.helpers.notification(err.response.data.data, 'error', this)
					}
				}
			})
		},
		validateCompanyNumber(number) {
			if (!number.includes('-')) {
				return true
			}
			if (number.split('-')[0].length === 6 && number.split('-')[1].length === 4) {
				return false
			}
			return true
		},
		removeAccount(partner, id) {
			this.$services.helpers.notification(this.$t('pleaseWait'), 'progress', this)
			this.$store.dispatch('house/removeAccount', {URL: this.$services.endpoints.IN_HOUSE_UPDATE_FIRM + `${partner}/${id}`})
				.then(res => {
					if (res.status === 200) {
						this.getFirmAccountants(partner)
						this.$services.helpers.notification(this.$t('accountantRemove'), 'success', this)
					}
				}).catch(err => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.removeAccount(id)
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out-accountant')
							})
					} else {
						this.$services.helpers.notification(err.response.data.data, 'error', this)
					}
				}
			})
		},
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
@import "~vue-tour/dist/vue-tour.css";

.table-bordered thead th, .table-bordered thead td {
	background: #fff;
	border: 1px solid #ffffff;

}

.custom-btn-pay {
	background-color: #F167CA !important;
	border-color: #F167CA !important;
	font-weight: 800;
}

.custom-card-section {
	box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
	border-width: 0;
	transition: all .2s;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(26, 54, 126, 0.125);
}

.custom-save-btn {
	background-color: #2CDCA7 !important;
	border-color: #2CDCA7 !important;
	font-weight: 800;
}

.override-form-field {
	border: 0;
	border-bottom: 2px solid rgb(64, 138, 235);
	box-shadow: none;
	background-color: #fff !important;
	border-radius: unset !important;
}

.col-form-label {
	padding-top: 1rem !important;
	// font-weight: 800;
	// font-size: 14px !important;
	white-space: nowrap;
}

.form-group {
	margin-bottom: 0.4rem !important;
}

.form-input {
	font-size: 0.8rem !important;
	padding: 0.2rem 0.75rem !important;
}

.col-form-label {
	padding-bottom: unset !important;
	padding-top: 0.5rem !important;
}

.form-control {
	border: 1px solid $form-border !important;
	// height: unset !important;
	padding: .175rem .75rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.theme-blue {
	color: $theme-secondary-color;
	border: 0.5px solid $theme-secondary-color !important;
}

.input-icons i {
	position: absolute;
	margin: 7px;
	top: 3px;
}

.input-icons {
	width: 100%;
	// margin-bottom: 10px;
}

.custom-search {
	padding-left: 1.5rem !important;
}

.button-margin {
	margin-right: 0px !important;
	margin-left: 5px !important;
}

.button-color-active {
	color: #efb3c7 !important;
	border: 1px solid #efb3c7 !important;
}
</style>
